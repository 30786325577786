
import { defineComponent, onMounted } from "vue";
import WorkdayResume from "@/components/workday/WorkdayResume.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import ApiService from "@/core/services/ApiService";
import EntitySelect from "@/components/EntitySelect.vue";
export default defineComponent({
  name: "workday-list",
  components: {
    WorkdayResume,
  },
  data() {
    return {
      loading: false,
      supervisorId: "",
      pendingDays: [],
      dueDays: [],
      supervisorList: [],
      validFUM: [],
      workDay: null,
    };
  },
  methods: {
    startDay() {
      ApiService.post("workdays", {
        supervisorId: this.supervisorId,
      }).then(() => {
        this.retrieveData();
      });
    },
    retrieveData() {
      this.loading = true;
      ApiService.query("workdays", {
        params: { today: true },
      }).then((response) => {
        this.workDay = response.data.entities;
        this.validFUM = response.data.validFUM;
        if (response.data.supervisor !== null) {
          this.supervisorId = response.data.supervisor.toString();
        }
        this.loading = false;
      });
    },
  },
  mounted() {
    ApiService.query("index/users", {
      params: { permission: ["supervise_user"] },
    }).then((response) => {
      this.supervisorList = response.data;
    });

    setCurrentPageBreadcrumbs("Parte Diario", ["Listado"]);
    MenuComponent.reinitialization();
    this.retrieveData();
  },
});
